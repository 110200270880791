import React from 'react'
import Container from '@material-ui/core/Container';
import LibraryWrapper from './LibraryWrapper'
import { Pet, Typography } from '../../components/library'

function progress() {
    return (
        <LibraryWrapper>
            <Container maxWidth="sm">
                <Pet 
                    help="Meaning of this label"
                    id="a-pet"
                >
                    <Typography variant="body1">Good job with pairing the wearable! You’ve earned 1 bone.</Typography>
                </Pet>
            </Container>
        </LibraryWrapper>
    )
}
export default progress
